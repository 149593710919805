<!-- Mto particular de Hornos -->

<template>
  <div class="hornos_M">
    <!-- Base Mto -->
    <base_M
      v-if="schema"
      :mto="mto"
      :btra="btra"
      :titulo="titulo"
      :schema="schema"
      :accion="accion"
      @onEvent="event_capture"
    >
      <!-- Slot con los controles del Mto -->
      <template v-slot:ctrls="{}">
        <v-row no-gutters>
          <v-col md="8">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.name.value"
              :label="schema.ctrls.name.label"
              :disabled="!edicion"
            >
            </v-text-field>

            <ctrlfinder :schema="schema.ctrls.atlas" :edicion="edicion">
            </ctrlfinder>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.dire.value"
              :label="schema.ctrls.dire.label"
              :disabled="!edicion"
            >
            </v-text-field>

            <ctrlfinder :schema="schema.ctrls.fun_id" :edicion="edicion">
            </ctrlfinder>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.contacto.value"
              :label="schema.ctrls.contacto.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>

          <v-col md="4">
            <v-select
              v-bind="$select"
              v-model="schema.ctrls.tipo.value"
              :items="get_tipoconcesion"
              :disabled="!edicion"
              :label="schema.ctrls.tipo.label"
              item-value="id"
              item-text="name"
            >
            </v-select>

            <ctrlgeo
              :schema="schema"
              base="gmap"
              icono="mdi-map-check-outline"
              :edicion="edicion"
            >
            </ctrlgeo>

            <ctrlurl :schema="schema.ctrls.url" :edicion="edicion"> </ctrlurl>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.contactotlf.value"
              :label="schema.ctrls.contactotlf.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="8">
            <v-subheader> TARIFAS </v-subheader>

            <v-row no-gutters>
              <v-col md="6">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.incinera.value"
                  :label="schema.ctrls.incinera.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.urna.value"
                  :label="schema.ctrls.urna.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.funda.value"
                  :label="schema.ctrls.funda.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.graba.value"
                  :label="schema.ctrls.graba.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.derechos.value"
                  :label="schema.ctrls.derechos.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.placa.value"
                  :label="schema.ctrls.placa.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.tramit.value"
                  :label="schema.ctrls.tramit.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.marcapasos.value"
                  :label="schema.ctrls.marcapasos.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="6">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.pack.value"
                  :label="schema.ctrls.pack.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.residuos.value"
                  :label="schema.ctrls.residuos.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.restos.value"
                  :label="schema.ctrls.restos.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.relicarios.value"
                  :label="schema.ctrls.relicarios.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.memorial.value"
                  :label="schema.ctrls.memorial.label"
                  :disabled="!edicion"
                >
                </v-text-field>

                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.varios.value"
                  :label="schema.ctrls.varios.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="4">
            <v-subheader> NÚMERO INCINERACIONES </v-subheader>

            <v-row no-gutters>
              <v-col md="2">
                <v-subheader> CADAV </v-subheader>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c1.value"
                  :label="schema.ctrls.c1.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c2.value"
                  :label="schema.ctrls.c2.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c3.value"
                  :label="schema.ctrls.c3.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c4.value"
                  :label="schema.ctrls.c4.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.c5.value"
                  :label="schema.ctrls.c5.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col md="2">
                <v-subheader> RESTOS </v-subheader>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.r1.value"
                  :label="schema.ctrls.r1.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.r2.value"
                  :label="schema.ctrls.r2.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.r3.value"
                  :label="schema.ctrls.r3.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.r4.value"
                  :label="schema.ctrls.r4.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="2">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.r5.value"
                  :label="schema.ctrls.r5.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col md="6">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.hornon.value"
                  :label="schema.ctrls.hornon.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>

              <v-col md="6">
                <v-text-field
                  v-bind="$input"
                  v-model="schema.ctrls.hornotipo.value"
                  :label="schema.ctrls.hornotipo.label"
                  :disabled="!edicion"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col md="8">
            <ctrlobs :schema="schema.ctrls.obs" :edicion="edicion"> </ctrlobs>
          </v-col>

          <v-col md="4">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.modificado.value"
              :label="schema.ctrls.modificado.label"
              :disabled="!edicion"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
    </base_M>
  </div>
</template>

<script>
import { mixinMto } from "@/mixins/mixinMto.js";
import plugs from "@/common/general_plugs";
const base_M = () => plugs.groute("base_M.vue", "base");
const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
const ctrlgeo = () => plugs.groute("ctrlgeo.vue", "comp");
const ctrlurl = () => plugs.groute("ctrlurl.vue", "comp");
const ctrlobs = () => plugs.groute("ctrlobs.vue", "comp");

export default {
  mixins: [mixinMto],
  components: { base_M, ctrlfinder, ctrlgeo, ctrlurl, ctrlobs },
  props: {
    id_accion: { type: Object, default: null },
    btra: { type: [Array, Object], default: undefined }
  },

  data() {
    return {
      mto: "hornoS",
      titulo: "Mantenimiento de Hornos",
      schema: null,
      id: this.id_accion.id,
      accion: this.id_accion.accion,
      edicion: false
    };
  },

  computed: {
    //
    get_tipoconcesion() {
      return this.$store.state.datos_iniciales.tipo_cem;
    }
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log("*** event_capture hornos_M. Accion: " + evt.accion + " ***");

      if (this.$isFunction(this[evt.accion])) this[evt.accion](evt);
      this.acciones_Mto(evt);
    }
  }
};
</script>
